import React,{useEffect, useState} from 'react'
import { Link, graphql } from "gatsby"

import axios from 'axios'
import Layout from "./../../components/layout"
import ThemeContext from "./../../context/ThemeContext"
//import { GatsbyImage, getImage } from 'gatsby-plugin-image'
//import useWindowSize from './../../hooks/useGatsbyWindowSize'
//import { useMediaQuery } from 'react-responsive'
import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { CustomEase } from "gsap/CustomEase"
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll'
//import SvgEl from '././../../components/SvgEl'
//import LOGO from '././../../svg/logos'
import SuitesList from '././../../components/SuitesList'




gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
gsap.config({autoKillThreshold: 1});


const SuitesPage = ({ data }) => {

  //console.log(data.photos.edges);

  const [jsonContent, setJsonContent] = useState('');
//  const [isLoaded, setIsLoaded] = useState(false);
//  const [isMobile, setIsMobile] = useState(false);


  //const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  //const {width,height} = useWindowSize();
  //  var device = '';






  function loadHtml(url) {
  var _json = url;
  axios.get(_json)
.then(function (response) {

  //var _jsonContent = response.data.gallery;
  //device = response.data.device;




  var _jsonContent =  response.data.content;
  setJsonContent(_jsonContent);
//  setIsLoaded(true);
});
}










  useEffect(() => {
    //setIsMobile(mobile);


  loadHtml('https://discover.lilleymansion.com/app/v1/page/2/suites');
  },[]);


  return (

    <ThemeContext.Consumer>
        {theme => (
    <div id="page">


    <div id="page-wrapper" className="pt-5">


    <div className="container pt-5 p-2 text-center">
    <ScrollAnimation animateIn="animate__animated animate__fadeInUp animate__delay-.3s">
    <h1 className="titling pt-5">OUR SUITES</h1>
    <p className="text-center p-2"> <a className="text-center p-2 font-lm" href="/amenities/">AMENITIES</a> | <a className="text-center p-2 font-lm" href="/policies">POLICIES</a></p>
    </ScrollAnimation>
    </div>


    </div>


    <div
    style={{
      margin: `0`,
      padding: `20px`,
      color:`#111`
    }}
    >

    <SuitesList/>

    <div className="container pt-4 p-2" dangerouslySetInnerHTML={{ __html: jsonContent }} />


    </div>

    </div>

  )}
      </ThemeContext.Consumer>

  )
}

SuitesPage.Layout = Layout

export default SuitesPage

export const Head = (props) => (
  <>
    <title>The Lilley Mansion Bed and Breakfast</title>
    <meta name="description" content="The Lilley Mansion features four meticulously appointed suites each with an oversized spa retreat bathroom, clawfoot tub, and separate marble showers. Each room also features a plush king size mattress." />
    <meta property="og:url" content={'https://lilleymansion.com'+ props.location.pathname} />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="The Lilley Mansion" />
    <meta property="og:description" content="The Lilley Mansion features four meticulously appointed suites each with an oversized spa retreat bathroom, clawfoot tub, and separate marble showers. Each room also features a plush king size mattress." />
    <meta property="og:image" content="https://lilleymansion.com/og/lilley-mansion.jpg" />
    <link rel="preload" href="https://discover.lilleymansion.com/app/v1/page/2/suites" as="fetch"/>
    <link rel="preload" href="https://discover.lilleymansion.com/api/v1/suites/lilley-suite" as="fetch"/>
    <link rel="preload" href="https://discover.lilleymansion.com/api/v1/suites/savidge-suite" as="fetch"/>
    <link rel="preload" href="https://discover.lilleymansion.com/api/v1/suites/cutler-suite" as="fetch"/>
    <link rel="preload" href="https://discover.lilleymansion.com/api/v1/suites/sisson-suite" as="fetch"/>
  </>
)

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }

    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "home"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
  }
`
