import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ImageRenderer from '../ImageRenderer'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import SvgEl from './../SvgEl'
import LOGO from './../../svg/logos'

const SuitesList = () => {

  const [jsonContent, setJsonContent] = useState('');
  var source_url = '';
  var newArr =[];
  function loadJson(url) {
  var _json = url;
  axios.get(_json)
  .then(function (response) {

    //console.log(response);


  var _jsonContent = response.data.data.map( (item, index) => {


    return (
      <div key={'d_' + item.id} className={'col-12 col-sm-12 col-md-6 overflow-hidden -row event-row-is-' + item.isnext}>

      <ScrollAnimation animateOnce={true} animateIn="animate__animated animate__fadeInUp animate__delay-.2s">
      <a href={"/suites/suite#/" + item.slug}>
       <ImageRenderer
          styleclass="img-blog"
          url={source_url + item.image_thumb['url']}
          width={item.image_thumb['width'] }
          height={item.image_thumb['height']}
          alt_tag={'Lilley Mansion ' +item.name}
          />
          </a>

      <a href={"/suites/suite#/" + item.slug}>
      <div className="p-2 text-center">
      <div className="blog-logo suite-logo">
      <SvgEl svg={LOGO.MAIN} className=''/>
      </div>

      <h3 className="ttl-blog mt-2 pb-2 mb-1">{item.name}</h3>
      <div className="excerpt-blog text-center mt-2" dangerouslySetInnerHTML={{ __html: item.excerpt}}/>
      </div>
      </a>
      <div className="center text-center mb-5">
      <a className="button btn btn-secondary btn-secondary_blue btn-sm" href={item.reservation_url}>Reserve this suite</a>
      </div>
      </ScrollAnimation>
     </div>

   );

 });
  //device = response.data.device;
  //newArr = 'xxxx'; //response.data.data.map(itemsStructure);

 setJsonContent(_jsonContent);


//return newArr;


  });
  }




  useEffect(() => {

    //console.log('loaded')
      loadJson('https://discover.lilleymansion.com/api/v1/suites');
  },[]);


  return (

      <div className="row">
      {jsonContent}
      </div>

  );
};

export default SuitesList;
